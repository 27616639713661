import { Component, ChangeDetectorRef, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { SharedCheckoutService } from '../../services/sharedcheckout.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

declare let bootstrap: any;

@Component({
  selector: 'app-request-and-duplicate-cart-button',
  templateUrl: './request-and-duplicate-cart-button.component.html',
  styleUrls: ['./request-and-duplicate-cart-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestAndDuplicateCartButtonComponent implements OnInit, OnDestroy {

  @ViewChild('duplicateModal') duplicateModal: ElementRef;
  @ViewChild('purchaseOrderModal') purchaseOrderModal: ElementRef;
  @ViewChild('approveOrderModal') approveOrderModal: ElementRef;
  @ViewChild('rejectorderModal') rejectorderModal: ElementRef;
  @ViewChild('minimumOrderCartModal') minimumOrderCartModal: ElementRef;
  @ViewChild('commentinput') commentinput: ElementRef;

  public modalInstance: any;
  public legalEntityListSession: any[];
  public selectedLegalEntitySession: any;
  public legalEntityListNew: Observable<any>;
  public purchaseOrderNumber: string = '';
  public currentTotal: number = 0;
  public minimumOrderAmount: number = 0;
  public isMinimumOrderNotMet: boolean = false;
  isSubmitAttempted: boolean;
  acceptTerms: any;
  isReviewMode: boolean;
  showPOrder: boolean;
  showROrder: boolean = true;
  purchaseOrderNo: string;
  requesterOnly: boolean;
  requesterAndPurchaser: boolean;
  purchaserOnly: boolean;
  private _userRoles: any;
  cartName: any;
  address: any;
  deliveryMode: any;
  addressChangeQuotation: any;
  deliveryModeChangeQuotation: any;
  private subscriptions: Subscription = new Subscription();
  QuotationMode: boolean = false;
  nonQuatationPresent: boolean = false;
  originalCartId: string = '';
  isError: boolean;
  private unsubscribe$ = new Subject<void>();
  commentInput: string = '';

  constructor(
    private apiService: ApiService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    public sharedCheckoutService: SharedCheckoutService,
    private router: Router,
    private route: ActivatedRoute,
    private windowRef: WindowRef
  ) { }

  ngOnInit() {
    this.QuotationMode = false;
    this.address = null;
    this.deliveryMode = null;
    this.addressChangeQuotation = null;
    this.deliveryModeChangeQuotation = null;
    // Subscribe to the current total and minimum order amount from the shared service
    this.sharedCheckoutService.currentTotal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(total => {
        this.currentTotal = total;
        this.checkMinimumOrderAmount();
      });

    this.sharedCheckoutService.minimumOrderAmount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(amount => {
        this.minimumOrderAmount = amount;
        this.checkMinimumOrderAmount();
      });

    this.subscriptions.add(
      this.apiService.address$.subscribe({
        next: (address) => {
          this.address = address;
          this.cdr.markForCheck();
        },
        error: (err) => console.error('Error receiving address:', err),
      })
    );

    this.subscriptions.add(
      this.apiService.deliveryMode$.subscribe({
        next: (deliveryMode) => {
          this.deliveryMode = deliveryMode;
          this.cdr.markForCheck();
        },
        error: (err) => console.error('Error receiving delivery mode:', err),
      })
    );

    this.subscriptions.add(
      this.apiService.addressChangeQuotation$.subscribe({
        next: (addressChangeQuotation) => {
          this.addressChangeQuotation = addressChangeQuotation;
          this.cdr.markForCheck();
        },
        error: (err) => console.error('Error receiving address for quotation:', err),
      })
    );

    this.subscriptions.add(
      this.apiService.deliveryModeChangeQuotation$.subscribe({
        next: (deliveryModeChangeQuotation) => {
          this.deliveryModeChangeQuotation = deliveryModeChangeQuotation;
          this.cdr.markForCheck();
        },
        error: (err) => console.error('Error receiving delivery mode for quotation:', err),
      })
    );

    this.subscriptions.add(
      this.apiService.originalCartId$.subscribe({
        next: (originalCartId) => {
          this.originalCartId = originalCartId;
          this.cdr.markForCheck();
        },
        error: (err) => console.error('Error receiving original cart id:', err),
      })
    );

    this.sharedCheckoutService.isQuatationData.subscribe((data) => {
      if (data !== undefined) {
        this.QuotationMode = data;
        this.cdr.markForCheck();
      }
    });

    this.sharedCheckoutService.isNonQuatationData.subscribe((data) => {
      if (data !== undefined) {
        this.nonQuatationPresent = data;
        this.cdr.markForCheck();
      }
    });

    this.legalEntityListSession = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities.map((item) => item.name)
      : [];

    this.selectedLegalEntitySession = this.storageService.defaultLegalEntity
      ? this.storageService.defaultLegalEntity.name
      : this.legalEntityListSession.length > 0
        ? this.legalEntityListSession[0]
        : '';

    this.route.queryParams.subscribe((params) => {
      this.isReviewMode = (params['review'] === 'true');
    });

    this.sharedCheckoutService.showPlaceOrder.subscribe((data) => {
      this.showPOrder = this.showROrder = data;
      this.cdr.markForCheck();
    });

    this.determineUserRoles();
    this.cdr.markForCheck();
  }

  checkMinimumOrderAmount(): void {
    this.isMinimumOrderNotMet = this.currentTotal < this.minimumOrderAmount;
  }

  private get userRoles() {
    if (!this._userRoles) {
      const userRolesString = this.storageService.userRoles;
      this._userRoles = userRolesString ? (userRolesString) : null;
    }
    return this._userRoles;
  }

  private determineUserRoles() {
    const roles = this.userRoles;
    this.requesterOnly = roles.includes('Requester') && !roles.includes('Purchaser');
    this.requesterAndPurchaser = roles.includes('Requester') && roles.includes('Purchaser');
    this.purchaserOnly = roles.includes('Purchaser') && !roles.includes('Requester');
  }

  onValueChange(event: DropdownChangeEvent) {
    const selectedValue = event.value;
    this.selectedLegalEntitySession = selectedValue;
    const index = this.legalEntityListSession.indexOf(selectedValue);
    if (index > -1) {
      this.globalService.updateLegalEntity(index);
    }
  }

  //Dont remove any logs or comment
  onPlaceOrder(): void {
    console.log('RequestAndDuplicateCartButtonComponent: onPlaceOrder triggered');
    this.sharedCheckoutService.setClickBtn(true);

    // Validate address and delivery mode
    if (this.storageService.cartCode && (!this.address || !this.deliveryMode)) {
      console.error('Please select a shipping address and a delivery mode.');
      return;
    }

    if (this.QuotationMode) {
      if (!this.addressChangeQuotation || !this.deliveryModeChangeQuotation) {
        console.error('Please select a shipping address and a delivery mode.');
        return;
      }
    }

    // Check minimum order amount
    if (this.isMinimumOrderNotMet) {
      if (this.minimumOrderCartModal) {
        this.modalInstance = new bootstrap.Modal(this.minimumOrderCartModal.nativeElement, {
          backdrop: 'static',
          keyboard: false
        });
        this.modalInstance.show();
        return;
      } else {
        return;
      }
    }

    const purchaseOrderInput = this.windowRef.document.getElementById('purchaseOrderNumber') as HTMLInputElement;
    if (!purchaseOrderInput) {
      console.error('Purchase order input field not found.');
      return;
    }

    this.purchaseOrderNo = purchaseOrderInput?.value;
    this.isSubmitAttempted = true;

    if (!this.acceptTerms) {
      return;
    }

    if (!this.purchaseOrderNo) {
      this.sharedCheckoutService.triggerShowModal();
      return;
    }

    this.purchaseOrderNo = encodeURIComponent(this.purchaseOrderNo);
    this.globalService.loadingSubject.next(true); // Start the spinner

    // Step 1: Place the order first
    this.apiService.placeOrder(this.purchaseOrderNo, this.storageService.checkoutCartGroupId).subscribe(
      (response) => {
        // Step 2: After placing the order, check roles and potentially trigger approveOrder
        const roles = this.userRoles;
        if (this.storageService.cartCode && ((roles.includes('Requester') && roles.includes('Purchaser')) || roles.includes('Purchaser'))) {
          // Step 3: Trigger approveOrder after placeOrder success
          this.apiService.approveOrder(this.purchaseOrderNo, this.storageService.checkoutCartGroupId).subscribe({
            next: (response) => {
              // Step 4: After approveOrder success, stop spinner and show modal
              this.globalService.loadingSubject.next(false); // Stop spinner
              this.modalInstance = new bootstrap.Modal(this.approveOrderModal.nativeElement, {
                backdrop: 'static',
                keyboard: false
              });
              this.modalInstance.show();
              this.approveOrderModal.nativeElement.addEventListener('hide.bs.modal', this.redirectToOrder);
            },
            error: (error) => {
              // Handle approval error
              console.error('Error approving order:', error);
              this.globalService.loadingSubject.next(false); // Stop spinner on error
            }
          });
        } else {
          // If no approval is needed (e.g., RequesterOnly role), stop the spinner and show the modal
          console.log('No approval needed, showing modal');
          this.globalService.loadingSubject.next(false); // Stop spinner
          this.modalInstance = new bootstrap.Modal(this.approveOrderModal.nativeElement, {
            backdrop: 'static',
            keyboard: false
          });
          this.modalInstance.show();
          this.approveOrderModal.nativeElement.addEventListener('hide.bs.modal', this.redirectToOrder);
        }
      },
      (error) => {
        console.error('Error placing order', error);
        this.globalService.loadingSubject.next(false); // Stop spinner on error
      }
    );
  }

  onRequestOrder() {
    console.log('RequestAndDuplicateCartButtonComponent: onRequestOrder triggered');
    this.sharedCheckoutService.setClickBtn(true);

    if (this.isMinimumOrderNotMet) {
      if (this.minimumOrderCartModal) {
        this.modalInstance = new bootstrap.Modal(this.minimumOrderCartModal.nativeElement);
        this.modalInstance.show();
        return;
      } else {
        return;
      }
    }
    if (this.nonQuatationPresent && (!this.address || !this.deliveryMode)) {
      console.error('Please select a shipping address and a delivery mode.');
      return;
    }

    if (this.QuotationMode && (!this.addressChangeQuotation || !this.deliveryModeChangeQuotation)) {
      console.error('Please select a shipping address and a delivery mode for quotation.');
      return;
    }

    const purchaseOrderInput = this.windowRef.document.getElementById('purchaseOrderNumber') as HTMLInputElement;
    this.purchaseOrderNo = purchaseOrderInput.value;
    this.isSubmitAttempted = true;

    if (!this.acceptTerms && !this.requesterOnly) {
      console.warn('Terms not accepted or user is not a requester.');
      return;
    }

    if (!this.purchaseOrderNo) {
      console.warn('No purchase order number entered.');
      this.sharedCheckoutService.triggerShowModal();
      return;
    }

    this.globalService.loadingSubject.next(true);

    this.apiService.requestOrder(this.purchaseOrderNo, this.storageService.checkoutCartGroupId).subscribe({
      next: (response) => {
        this.resetFormState();
        this.globalService.loadingSubject.next(false);
        this.modalInstance = new bootstrap.Modal(this.approveOrderModal.nativeElement, {
          backdrop: 'static',
          keyboard: false
        });
        this.modalInstance.show();
      },
      error: (error) => {
        console.error('Error placing order:', error);
        this.globalService.loadingSubject.next(false);
      }
    });
  }

  navigateToProducts(): void {
    if (this.modalInstance) {
      this.modalInstance.hide();
      this.removeBackdrop();
    }
    this.router.navigate([AppConstants.routeUrls.plp]);
  }

  private resetFormState() {
    this.apiService.updateAddress(null);
    this.apiService.updateDeliveryMode(null);
    this.apiService.updateAddressChangeQ(null);
    this.apiService.updateDeliveryModeChange(null);
    this.sharedCheckoutService.checkQuotationLength(null)
    this.sharedCheckoutService.checkNonQuatationLength(null)

    this.address = null;
    this.deliveryMode = null;
    this.addressChangeQuotation = null;
    this.deliveryModeChangeQuotation = null;

    this.purchaseOrderNo = '';
    const purchaseOrderInput = this.windowRef.document.getElementById('purchaseOrderNumber') as HTMLInputElement;
    if (purchaseOrderInput) {
      purchaseOrderInput.value = '';
    }

    this.isSubmitAttempted = false;
  }

  onRejectOrderRequest() {
    try {
      const cartGroupId = this.storageService.paramsCartGroupId;
      const cartCode = this.storageService.cartCode;
      const comment = this.commentInput?.trim() || '';

      if (!cartGroupId || !cartCode) {
        console.error('Missing necessary data for API call');
        return;
      }
      if(!this.commentInput){
         this.commentInput ?? '';
      }
      this.apiService.rejectOrderRequest(cartGroupId,comment).subscribe(
        (response) => {
          this.globalService.loadingSubject.next(false);
          this.removeBackdrop();
          this.modalInstance.hide();
          this.cdr.markForCheck();
          this.router.navigate([AppConstants.routeUrls.orderRequest]);
        },
        (error) => {
          console.error('Error removing cart', error);
        }
      );
    } catch (error) {
      console.error('Error in onRejectOrderRequest:', error);
    }
  }

  onApproveOrderRequest() {
    const purchaseOrderInput = this.windowRef.document.getElementById('purchaseOrderNumber') as HTMLInputElement;
    this.purchaseOrderNo = purchaseOrderInput.value;
    this.isSubmitAttempted = true;
    if (!this.acceptTerms) {
      return;
    }
    if (!this.purchaseOrderNo) {
      this.sharedCheckoutService.triggerShowModal();
      return;
    }
    this.globalService.loadingSubject.next(true);
    this.apiService.approveOrderRequest(purchaseOrderInput.value, this.storageService.paramsCartGroupId).subscribe({
      next: (response) => {
        this.globalService.loadingSubject.next(false);
        this.modalInstance = new bootstrap.Modal(this.approveOrderModal.nativeElement, {
          backdrop: 'static',
          keyboard: false
        });
        this.modalInstance.show();
        this.cdr.markForCheck();
        this.removeBackdrop();
      },
      error: (error) => this.globalService.loadingSubject.next(false)
    });
  }

  onRejectOrder(): void {
    this.modalInstance = new bootstrap.Modal(this.rejectorderModal.nativeElement);
    this.modalInstance.show();
  }

  redirectToOrder(): void {
    this.cdr.markForCheck();
    this.modalInstance.hide();
    this.router.navigate([AppConstants.routeUrls.orderHistory]);
    this.removeBackdrop();
  }

  private removeBackdrop(): void {
    const backdrop = this.windowRef.document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
  }

  onDuplicateCart(): void {
    this.modalInstance = new bootstrap.Modal(this.duplicateModal.nativeElement);
    this.modalInstance.show();
  }

  confirmDuplicateCart() {
    const cartName = (this.windowRef.document.getElementById('companyInput') as HTMLInputElement).value.trim();
    let selectedLegalEntity = this.selectedLegalEntitySession;
    // Remove any prefix like "3: " from selectedLegalEntity
    selectedLegalEntity = selectedLegalEntity.split(': ')[1] || selectedLegalEntity;
    // Validate the cart name
    if (!cartName) {
      this.isError = true;
      console.error('Cart name is missing.');
      return;
    }
    // Check if legalEntityListSession has values
    if (!this.legalEntityListSession || this.legalEntityListSession.length === 0) {
      console.error('Legal entity list is empty or not populated.');
      return;
    }
    const currentLegalEntity = this.storageService.defaultLegalEntity;
    // Use trimmed and cleaned comparison to find the selected entity
    const index = this.legalEntityListSession.findIndex(entity =>
      entity.trim().toLowerCase() === selectedLegalEntity.trim().toLowerCase()
    );
    if (index < 0) {
      console.error('Selected legal entity is invalid or not found in the list.');
      console.log('Selected Legal Entity:', selectedLegalEntity);
      console.log('Legal Entity List:', this.legalEntityListSession);
      return;
    }
    // Get the legal entity ID using the index
    const legalEntityId = this.storageService.userLegalEntities[index]?.uid;
    if (!legalEntityId) {
      console.error('No legal entity ID found for the selected entity.');
      return;
    }
    // Reset the error flag
    this.isError = false;
    // Proceed with the cart duplication
    this.globalService.loadingSubject.next(true);
    this.apiService.postDuplicateCart(this.originalCartId, legalEntityId, cartName)
      .subscribe({
        next: (response) => {
          if (response?.body?.savedCartData?.code) {
            this.storageService.activeCart = response.body.savedCartData;
            this.storageService.cartId = response.body.savedCartData.code;
            if (currentLegalEntity?.name !== selectedLegalEntity) {
              this.globalService.updateLegalEntity(index);
            }
            this.router.navigate([AppConstants.routeUrls.cart]);  // Redirect to cart on success
            this.removeBackdrop();
            this.modalInstance.hide();
            this.globalService.loadingSubject.next(false);
          }
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
          // Ensure modal closes, even on error
          if (this.modalInstance) {
            this.modalInstance.hide();
          }
          // Check for specific backend error
          if (error?.errors?.some(err => err.type === 'RuntimeError')) {
            // Redirect to cart even if the API fails
            this.router.navigate([AppConstants.routeUrls.cart]);  // Force redirect to cart on error
          } else {
            console.error('Error duplicating cart:', error);
          }
        }
      });
  }

  cancelRejectRequest() {
    this.commentinput.nativeElement.value = '';
  }

  ngOnDestroy() {
    // Reset the values
    this.address = null;
    this.deliveryMode = null;
    this.QuotationMode = false;
    this.subscriptions.unsubscribe();
    this.nonQuatationPresent = false;
    this.resetFormState();
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}